<template>
  <div>
    <CButton v-if="false" @click="showItems">SHOW</CButton>
    <CNav fill variant="tabs">
      <CNavItem
        v-for="(member, index) in $store.state.your_family"
        :active="isActive(index)">
        <div @click="isShow(index)">
          <CImg
            style="width:auto;height:40px"
            shape="rounded-circle"
            :src="_get_avatar(member['id'])"
          />
          {{member['nickname']}}</div>
      </CNavItem>
    </CNav>
    <!-- お仕事設定のカード -->
    <CCard v-if="items['jobs'].length > 0">
      <CCardHeader>お仕事のアクティビティ</CCardHeader>
      <CCardBody>
        <CRow v-for="job in items['jobs']" style="margin-bottom: 5px">
          <CCol lg="1" col="2">
            <CImg
              style="width:auto;height:35px"
              shape="rounded-circle"
              :src="_get_avatar(job['client_id'])"
            />
          </CCol>
          <CCol lg="11" col="10">
            <CRow>
              <CCol col="12">
                タイトル「
                <span>{{job['title']}}</span>
                」を作成しました。現在のお仕事のステータスは「
                <span style="margin-right: 5px">{{_get_status_text(Number(job['status']))}}</span>
                」です。
              </CCol>
              <CCol col="12">
                <span
                  style="font-weight: lighter; margin-right: 5px">
                  {{getShortDate(job['created'], 'JST', 6)}}
                </span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- いいねのカード -->
    <CCard v-if="items['favorites'].length > 0">
      <CCardHeader>いいねのアクティビティ</CCardHeader>
      <CCardBody>
        <CRow v-for="favorite in items['favorites']">
          <CCol lg="1" col="2">
            <CImg
              style="width:auto;height:35px"
              shape="rounded-circle"
              :src="_get_avatar(favorite['user_id'])"
            />
          </CCol>
          <CCol lg="11" col="10">
            <CRow>
              <CCol col="12">
                <div v-if="favorite['job']">
                  タイトル「{{favorite['job']['title']}}」のお仕事に いいね をしました。
                </div>
                <div v-else-if="favorite['offer']">
                  「{{favorite['offer']['offer_text']}}」に いいね をしました。
                </div>
              </CCol>
              <CCol col="12">
                <span
                  style="font-weight: lighter; margin-right: 5px">
                  {{getShortDate(favorite['created'], 'JST', 6)}}
                </span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- オファーのカード -->
    <CCard v-if="items['offers'].length > 0">
      <CCardHeader>質問や提案のアクティビティ</CCardHeader>
      <CCardBody>
        <CRow v-for="offer in items['offers']">
          <CCol lg="1" col="2">
            <CImg
              style="width:auto;height:35px"
              shape="rounded-circle"
              :src="_get_avatar(offer['worker_id'])"
            />
          </CCol>
          <CCol lg="11" col="10">
            <CRow>
              <CCol col="12">
                タイトル「{{offer['job']['title']}}」のお仕事にコメント
                「{{offer['offer_text']}}」と書きました。
              </CCol>
              <CCol col="12">
              <span
                style="font-weight: lighter; margin-right: 5px">
                {{getShortDate(offer['created'], 'JST', 6)}}
              </span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- 写真のカード -->
    <CCard v-if="items['images'].length > 0">
      <CCardHeader>質問や提案のアクティビティ</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol v-for="image in items['images']" lg="3" col="6" style="margin-bottom: 15px; padding:0 5px 0 5px">
            <CRow>
              <CCol col="12">
                <span
                  style="font-weight: lighter; margin-right: 5px">
                  {{getShortDate(image['created'], 'JST', 6)}}
                </span>
              </CCol>
            </CRow>
            <CImg
              style="width:100%;height:auto"
              :src="_get_job_image(image['job_id'], image['user_id'], image['name'])"
            />
            <CRow>
              <CCol col="12">
                {{titleForImage(image)}}
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: "ActivityLog",
  mixins: [Comm],/* ここでミックスインを追加 */
  components: {},
  props: {
  },
  mounted() {
    this.initShow()
    this.getItems()
  },
  watch: {},
  computed: {
    isActive: function() {
      return function(num){
        return num === 0
      }
    },
    titleForImage: function(){
      return function(image){
        if(image['job_id'] === 0){
          return "アイコン画像を更新しました。"
        }else{
          return `タイトル「${image['job']['title']}」にアップしました。`
        }
      }
    },
  },
  data() {
    return {
      show: [],
      items: {
        jobs: [],
        offers: [],
        favorites: [],
        images: []
      },
    }
  },
  methods: {
    showItems(){
      console.log(this.items)
    },
    showStore(){
      console.log(this.$store.state)
    },
    async getItems(){
      this.items = await this._get_activity_logs(1)
    },
    initShow: function(){
      for(let i = 0; i < this.$store.state.your_family.length; i++){
        this.show.push(false)
      }
      this.show.splice(0, 1, true)
    },
    async isShow(num) {
      this.items = await this._get_activity_logs(this.$store.state.your_family[num].id)
    }
  }
}
</script>

<style scoped>
</style>
